import type { FragmentType, Page } from '@ocp-zmarta/content-sdk'
import type { useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import type { Ref } from 'vue'

interface Fragment extends FragmentType {
  data?: {
    trustpilotData?: {
      stars?: number
      scoreTemplate?: string
    }
  }
}

export function getFragmentQuery (ctx: NonNullable<ReturnType<typeof useSSRContext>>, pageData: Ref<Page | undefined>): object {
  const fragment = pageData?.value?.content?.hero?.fragment as Fragment

  const trustStars = fragment?.data?.trustpilotData?.stars
  const trustTemplate = fragment?.data?.trustpilotData?.scoreTemplate

  return {
    locale: ctx?.locale,
    op_user_id: ctx?.userId,
    ...(ctx?.referer ? { referer: ctx?.referer } : {}),
    ...(trustStars ? { trust_stars: trustStars } : {}),
    ...(trustTemplate ? { trust_template: trustTemplate } : {})
  }
}
