/** globals document **/

export async function waitForElement (selector: string, wait: number) {
  return await new Promise(resolve => {
    if (document.querySelector(selector)) {
      resolve(true)
      return
    }

    const interval = setInterval(async () => {
      if (document.querySelector(selector)) {
        clearInterval(interval)
        clearTimeout(timeout)

        resolve(true)
      }
    }, 300)

    const timeout = setTimeout(
      () => {
        clearInterval(interval)
        clearTimeout(timeout)

        resolve(false)
      }, wait)
  })
}
