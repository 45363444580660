/* globals hj */

import { hotjarInit } from './hotjar-init'

export function hotjarTagRecording (tag: string) {
  if (typeof window === 'undefined') return

  hotjarInit()
  // @ts-expect-error hotjar
  hj('tagRecording', [tag])
}
