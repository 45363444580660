import type { useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { metric } from '../utils/metric'

interface Input {
  name: string
  path?: string
  query?: object
  ctx?: NonNullable<ReturnType<typeof useSSRContext>>
}

interface Response {
  url: string
  headers: object
}

export function getFragment ({ name, path, query, ctx }: Input): Response {
  const response = {
    url: '',
    headers: {}
  }

  try {
    let url = ctx?.fragments?.[name]?.url
    const headers = ctx?.fragments?.[name]?.headers

    if (!url) return response
    url = url.includes('http')
      ? new URL(`${url}${path}`)
      : new URL(`https://dummy.se${url}${path}`)

    const locale = ctx?.locale as string
    if (locale) url.searchParams.append('locale', locale)

    const referer = ctx?.referer as string
    if (referer) url.searchParams.append('referer', referer)

    if (query) {
      Object.keys(query).forEach(key => {
        url.searchParams.append(key, query[key as keyof typeof query])
      })
    }

    return {
      url: url.href?.replace('https://dummy.se', ''),
      headers: {
        brand: ctx?.brand,
        market: ctx?.market,
        country: ctx?.market,
        ...headers
      }
    }
  } catch {
    metric('fragment', { status: 'error', name, path })

    return response
  }
}
