export function hotjarInit () {
  if (typeof window === 'undefined') return
  // @ts-expect-error hotjar
  if (typeof window.hj !== 'undefined') return

  /* istanbul ignore next */
  // @ts-expect-error hotjar
  window.hj = window.hj || function () {
    // @ts-expect-error hotjar
    (hj.q = hj.q || []).push(arguments)
  }
}
