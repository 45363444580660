import { getServerState } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
import { detectIncognito } from 'detectincognitojs'
import { hotjarTagRecording } from './hotjar-tag-recording'
import { log } from './log'
import { metric } from './metric'
import { waitForElement } from './wait-for-element'

export async function checkIfHeaderIsLoaded () {
  const path = getServerState('path')
  const params = window.location.search
  const incognito = await detectIncognito()

  const found = await waitForElement('.zmarta-header', 3000)
  metric('header', { status: found ? 'found' : 'not_found', path })

  if (!found) {
    hotjarTagRecording('header/not-found')

    log('warning', 'header/not-found', {
      path,
      params,
      browser: incognito?.browserName,
      incognito: incognito?.isPrivate
    })

    return
  }

  const loaded = await waitForElement('.zmarta-header.zmarta-header--loaded', 10000)
  metric('header', { status: loaded ? 'loaded' : 'not_loaded', path })

  if (!loaded) {
    hotjarTagRecording('header/not-loaded')

    log('warning', 'header/not-loaded', {
      path,
      params,
      browser: incognito?.browserName,
      incognito: incognito?.isPrivate
    })
  }
}
