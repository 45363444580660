import { request } from '../../utils/request'

export async function metric (name: string, labels: object) {
  if (!name || !labels) return

  return await request({
    method: 'POST',
    url: '/app-pages/metric',
    body: {
      name,
      labels
    }
  })
}
